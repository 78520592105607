import React from 'react'
import styled from 'styled-components'
import RowWrapper from "../utils/rowWrapper";

import Contact from '../components/contact-button';

const Container = styled.div`
  margin-top: 6rem;
  background-color: ${props => props.theme.colors.color};
  padding: 4rem;
  text-align: center;

  h5 {
    font-family: "Museo Sans Rounded 300", sans-serif;
    color: ${props => props.theme.colors.body};
    font-size: 3rem;
    font-weight: normal;
    text-transform: none;

    @media ${props => props.theme.breaks.down('sm')} {
      font-size: 2.5rem;
    }

  }

  h4 {
    font-family: "Museo Sans Rounded 500", sans-serif;
    font-size: 3rem;
    text-transform: none;
    color: ${props => props.theme.colors.body};
    letter-spacing: 1px;
    margin-bottom: .5rem;
    text-align: center;

    @media ${props => props.theme.breaks.down('sm')} {
      font-size: 2rem;
    }
  }

  h6 {
    font-family: "Museo Sans Rounded 100", sans-serif;
    text-transform: none;
    font-size: 3rem;
    color: ${props => props.theme.colors.body};
    text-align: center;

    @media ${props => props.theme.breaks.down('sm')} {
      font-size: 2rem;
    }
  }

  @media ${props => props.theme.breaks.down('sm')} {
    padding: 2rem;
  }
`

// const Flex = styled.div`
//   display: flex;
//   justify-content: flex-start;
// `
 
const Footer = ({statement, cta, subheader}) => {
 
 return (
  <Container>
      <Contact color="white" pos="4rem" left="4%" />
    <RowWrapper>
        {statement ? <div style={{}}>
        <h5>{statement}</h5>
        </div> : null}
        {cta || subheader ? <div style={{}}>
        <h4>{cta}</h4>
        <h6>{subheader}</h6>
        </div> : null}
    </RowWrapper>
  </Container>
 )
}

export default Footer;