import React, { useEffect } from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import { getImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import RowWrapper from '../utils/rowWrapper';

import Header from "../components/header-section";
import BracketRight from "../images/bracket-right.png";
import BracketLeft from "../images/bracket-left.png";

import Footer from "../components/footer";

import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

// import Contact from '../components/contact-button';


gsap.registerPlugin(ScrollTrigger)

const Main = styled.div`
  margin-top: 8rem;

h1 {
    font-size: 6rem;
    color: ${props => props.theme.colors.color};
    text-align: center;

    
  @media ${props => props.theme.breaks.down('md')} {
    font-size: 5rem;
  }

    @media ${props => props.theme.breaks.down('sm')} {
      font-size: 3rem;
      width: 70%;
      margin: 0 auto;
      line-height: 3.5rem;
    }

  }
`

const WorkSection = styled.div`
  margin: 0 auto;
  margin-top: 15rem;
  width: 70%;
  overflow-x: hidden;

  @media ${props => props.theme.breaks.down('md')} {
    width: 95%;
    margin-top: 8rem;
  }
`

const WorkContainer = styled.div`
  display: flex;
  flex-direction: ${props => props.dir !== "reverse" ? "row" : "row-reverse"};
  margin: 7rem 0;
  
  @media ${props => props.theme.breaks.down('sm')} {
    margin: 4rem 0;
  }
`

const WorkText = styled.div`
  margin: 0 7rem;
  /* width: 50%; */
  h3 {
    font-family: "Museo Sans Rounded 700", sans-serif;
    font-size: 5rem;
    text-transform: uppercase;

    @media ${props => props.theme.breaks.down('sm')} {
      font-size: 2rem;
    }
  }

  p {
    font-family: "Museo Sans Rounded 100", sans-serif;
    font-size: 2rem;
    width: 30rem;
    margin-top: 2rem;
    line-height: 2.4rem;

    @media ${props => props.theme.breaks.down('sm')} {
      width: 90%;
    }
  }

  @media ${props => props.theme.breaks.down('sm')} {
    margin: 0 2rem;
    margin-left: ${props => props.dir !== "reverse" ? "0" : "6rem"};
  }
`

const WorkBracket = styled.div`
  /* width: 50%; */
  img {
    width: 5rem;
  }
`

const PointSection = styled.div`
  margin-top: 12rem;

  @media ${props => props.theme.breaks.down('sm')} {
    margin-top: 8rem;
  }
`

const Point = styled.div`
  margin-top: 8rem;
  text-align: center;
  h4 {
    font-family: "Museo Sans Rounded 900", sans-serif;
    font-size: 5rem;
    color: ${props => props.theme.colors.color};
    max-width: 30rem;
    margin: 0 auto;

    @media ${props => props.theme.breaks.down('sm')} {
      font-size: 2.5rem;
      max-width: none;
    }
  }

  p {
    font-family: "Museo Sans Rounded 100", sans-serif;
    font-size: 2.5rem;
    max-width: 68rem;
    margin: 2rem auto;
    line-height: 3rem;

    @media ${props => props.theme.breaks.down('sm')} {
      font-size: 1.8rem;
      margin: 1rem 0;
      max-width: none;
    }

    span {
      font: inherit;
      font-family: "Museo Sans Rounded 300", sans-serif;
    }
    
  }

  @media ${props => props.theme.breaks.down('sm')} {
    margin-top: 4rem;
  }

`

 
const With = ({ data }) => {

  const image = getImage(data.file)

  const start = "60%"

  

  useEffect(() => {

    const screen = window.innerWidth;
    let odd, even;

    if (screen > 900) {
      odd = "-50rem"
      even = "40rem"
    } else if (screen > 600) {
      odd = "-45rem"
      even = "40rem"
    } else if (screen > 400) {
      odd = "-26rem"
      even = "26rem"
    } else {
      odd = "-24rem"
      even = "24rem"
    }


    

    const workTl1 = gsap.timeline({
      scrollTrigger: {
        trigger: ".work-1",
        start: `top ${start}`,
        toggleActions: "play none none reverse"
      }
    })

    const workTl2 = gsap.timeline({
      scrollTrigger: {
        trigger: ".work-2",
        start: `top ${start}`,
        toggleActions: "play none none reverse"
      }
    })

    const workTl3 = gsap.timeline({
      scrollTrigger: {
        trigger: ".work-3",
        start: `top ${start}`,
        toggleActions: "play none none reverse"
      }
    })

    workTl1.fromTo(".work-1", {x: odd}, {x: 0, duration: 1.2})

    workTl2.fromTo(".work-2", {x: even}, {x: 0, duration: 1.2})

    workTl3.fromTo(".work-3", {x: odd}, {x: 0, duration: 1.2})

  })

 
 return (
    <Layout pageTitle="Create A Space - With">
      <Header img={image} />

   <RowWrapper>


      <Main>
        <h1>HOW DOES THIS WORK?</h1>

        <WorkSection className="work-section">

          <WorkContainer className="work-1">
            <WorkText>
              <h3>1. Connection</h3>
              <p>We get to know you. And your needs. And your dreams.</p>
            </WorkText>
            <WorkBracket>
              <img src={BracketLeft} alt="" />
            </WorkBracket>
          </WorkContainer>

          <WorkContainer dir="reverse" className="work-2">
            <WorkText dir="reverse">
              <h3>2. Creation</h3>
              <p>We start thinking. And ordering. And building.</p>
            </WorkText>
            <WorkBracket>
              <img src={BracketRight}alt="" />
            </WorkBracket>
          </WorkContainer>

          <WorkContainer className="work-3">
            <WorkText>
              <h3>3. Completion</h3>
              <p>We install. We shake hands. You say “wow.”</p>
            </WorkText>
            <WorkBracket>
              <img src={BracketLeft} alt="" />
            </WorkBracket>
          </WorkContainer>

        </WorkSection>

        <PointSection>
          <Point>
            <h4>
            For the Dreamers
            </h4>
            <p>
            <span>We build the closets of your dreams,</span> down to the last detail. We have expert designers and expert listeners, ready to make your lifelong closet dreams a reality.
            </p>
          </Point>

          <Point>
            <h4>
            For the Designers
            </h4>
            <p>
            <span>We build the closets of your clients’ dreams,</span> whatever the project. We have expert collaborators, ready to work together to create something special.
            </p>
          </Point>

          <Point>
            <h4>
            For the Confused
            </h4>
            <p>
            <span>We build the closets of your dreams,</span> even if you’ve never had dreams about closets. We have expert question-askers, ready to discover the perfect closet for you.
            </p>
          </Point>
        </PointSection>

      </Main>

    </RowWrapper>

    <Footer cta="Call. Email. Visit." subheader="We’re here to help." />

    </Layout>
 )
}

export default With;


export const query = graphql`
query WithQuery {
  file(relativePath: { eq: "header-with.jpg" }) {
      childImageSharp {
        gatsbyImageData(width: 1500, height: 1000, quality: 100)
      }
    }
  }
`